<template>
  <div id="news">
    <Header2 nav="4" type="2"></Header2>

    <div class="loading" v-if="showloading">
      <van-loading type="spinner" color="#206C47" size="24px" vertical>
        加载中...
      </van-loading>
    </div>

    <div class="container" v-show="!showloading">
      <div class="banner">
        <img :src="bannerImg" />
      </div>

      <div class="sort" id="boxFixed" :class="isFixed ? 'tabsFixed' : ''">
        <div class="box">
          <div
            v-for="(item, index) in tabsList"
            :key="item.cate_id"
            class="sortItem"
            :class="sortActive == index ? 'sortActive' : ''"
            @click="changeSort(index, item.cate_id)"
          >
            {{ item.cate_name }}
          </div>
        </div>
      </div>

      <!-- <div class="loading2" v-if="showloading2">
        <van-loading type="spinner" color="#206C47" size="24px" vertical>
          加载中...
        </van-loading>
      </div> -->

      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
        :immediate-check="false"
        :error.sync="error"
        error-text="请求失败"
      >
        <div class="newsList" :class="isFixed ? 'listActive' : ''">
          <div
            class="newsItem"
            v-for="item in news"
            :key="item.Article_id"
            @click="getdetail(item.Article_id)"
          >
            <div class="left">
              <img class="leftimg" :src="item.Article_imgurl" />
            </div>
            <div class="right">
              <div class="pageTitle">{{ item.Article_title }}</div>
              <div class="pageTime">{{ item.add_date }}</div>
            </div>
          </div>
        </div>
      </van-list>
    </div>

    <Footer></Footer>
  </div>
</template>

<script>
import Header2 from "@/components/Header2.vue";
import Footer from "@/components/Footer.vue";
import { Icon, Loading, List } from "vant";
import qs from "qs";

export default {
  components: {
    Header2,
    Footer,
    [Icon.name]: Icon,
    [Loading.name]: Loading,
    [List.name]: List,
  },
  data() {
    return {
      bannerImg: "",
      showloading: true,
      // showloading2: true,
      tabsList: [], //分类
      sortActive: 0, //分类高亮状态
      cid: 0, //当前的分类id
      titlePage: 1, //当前页
      total: 1, //数据总条数
      news: [], //商品列表数据
      loading: true,
      finished: false,
      error: false,
      isFixed: false, //是否吸顶
      offsetTop: 100000, //吸顶元素距离页面顶部的距离
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);

    // 轮播图
    this.$axios
      .post("https://api1.pyua.net/index/banner", { type: 20 })
      .then((res) => {
        // console.log(res.data);
        if (res && res.code == 1) {
          this.bannerImg = res.data.arr[0].image;
          setTimeout(() => {
            this.showloading = false;
            // 计算吸顶元素到页面顶部的距离
            this.$nextTick(() => {
              let box = document.getElementById("boxFixed");
              this.offsetTop = box.offsetTop;
              // console.log("offsetTop", this.offsetTop);
            });
          }, 300);
        }
      });

    this.getSort();
  },
  methods: {
    //   获取分类
    getSort() {
      this.$axios
        .post(
          "https://mallxadmin.pyua.net/LKT/index.php?module=api&action=envelope&m=a_class"
        )
        .then((res) => {
          // console.log(res.List);
          if (res.status == 1) {
            let sort = res.List;
            sort.unshift({ cate_id: "0", cate_name: "全部" });
            this.tabsList = sort;
            // console.log(this.tabsList);
            this.cid = this.tabsList[0].cate_id;
            this.getData();
          }
        });
    },

    // 点击切换分类
    changeSort(index, id) {
      this.sortActive = index;
      this.cid = id;
      this.news = [];
      this.titlePage = 1; //当前页
      // this.showloading2 = true;
      this.loading = true;
      this.finished = false;
      this.getData();
    },

    // 获取新闻列表
    getData() {
      if (this.cid == 0) {
        this.cid = "";
      }
      let data1 = qs.stringify({
        class_id: this.cid,
        page: this.titlePage,
      });
      this.$axios
        .post(
          "https://mallxadmin.pyua.net/LKT/index.php?module=api&action=envelope&m=get_act",
          data1
        )
        .then((res) => {
          // console.log(res.article);
          if (res.status == 1) {
            this.news = [...this.news, ...res.article];
            this.total = res.total;
            this.loading = false;
            // this.showloading2 = false;
          }
        });
    },

    // 下拉加载数据
    onLoad() {
      if (this.news.length >= this.total) {
        this.finished = true;
        this.loading = false;
      } else {
        this.titlePage += 1;
        this.getData();
      }
    },

    // 跳转到新闻详情页
    getdetail(id) {
      // console.log(id);
      this.$router.push({ path: `/newsDetail?id=${id}` });
    },

    // 下拉页面，处理吸顶
    handleScroll() {
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      //如果被卷曲的高度大于吸顶元素到顶端位置 的距离
      // console.log(scrollTop,this.offsetTop)
      this.isFixed = scrollTop + 44 > this.offsetTop ? true : false;
    },
  },
};
</script>

<style lang="less" scoped>
#news {
  box-sizing: border-box;
}

.banner {
  margin-top: 2.75rem;
  font-size: 0;
  img {
    width: 100%;
  }
}

.loading {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  margin-top: 60%;
}

.loading2 {
  width: 100vw;
  height: 20vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

// 横向滚动条
.sort {
  // height: 2.875rem;
  padding: 0.2rem 1rem;
  box-shadow: 0 0 5px 1px #d2d2d2;
  background-color: #fff;
  width: 100%;
  box-sizing: border-box;
  .box {
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .sortItem {
    color: #666666;
    font-size: 0.8125rem;
    border-bottom: 2px solid #fff;
    white-space: nowrap;
    box-sizing: border-box;
    height: 100%;
    // text-align: justify;
    &:nth-last-child(1) {
      margin-right: 0;
    }
  }
  .sortActive {
    color: #206c47;
    border-bottom: 2px solid #206c47;
    padding: 0.5rem 0;
    box-sizing: border-box;
  }
}
// 隐藏横向滚动条
.sort::-webkit-scrollbar {
  display: none;
}
.tabsFixed {
  position: fixed;
  top: 2.75rem;
  left: 0;
  z-index: 100;
}

.newsList {
  margin-top: 1.875rem;
  .newsItem {
    padding: 1.5rem 1rem;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #f3f3f3;
    &:nth-child(1) {
      padding-top: 0;
    }
    .left {
      .leftimg {
        height: 5rem;
      }
    }
    .right {
      flex: 1;
      margin-left: 1rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .pageTitle {
        color: #206c47;
        font-size: 0.875rem;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
      .pageTime {
        color: #999999;
        font-size: 0.75rem;
        font-family: "DIN2";
      }
    }
  }
}

.listActive {
  margin-top: 4.75rem;
}
</style>